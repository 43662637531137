import { appMode } from '@/config';
import { GoogleAuthorizationResponse, GoogleUser } from '@/custom';
import * as OM from '@/model'
import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import { getQueryParams, parseJwt } from '@/utils';
import { createStore, StoreOptions } from 'vuex';

let storeState = {
    loginResponse: <OM.LoginResponse> null,
    user: <OM.User> null,
    selectedLanguage: 'it-IT',
    consts: {
        localizedValues: [],
        enabledLanguages: [],
    },
    beforeNavCbs: [],
    afterNavCbs: [],
    globalSpinner: 0,
    googleAuthentication: {
        token: "",
        userData: <GoogleUser>{}
    },
    googleAuthorization: {
        userData: <GoogleAuthorizationResponse>{}
    },
    isGoogleAuthorized: false,
    isTestMode: () => {
        return appMode == 'local' || appMode == 'test';
    },
    getParameters: {
        apiClaim: '',
        customerName: '',
        customerWebId: '',
        groupId: '',
        language: '',
        parameterCacheId: '',
        timestamp: '',
        estateId: '',
        businessUnit: '',
        userId: '',
        signature: '',
    },
}

const GET_PARAMETER_APICLAIM = 'apiClaim';
const GET_PARAMETER_CUSTOMERNAME = 'customerName';
const GET_PARAMETER_CUSTOMERWEBID = 'customerWebId';
const GET_PARAMETER_GROUPID = 'groupId';
const GET_PARAMETER_LANGUAGE = 'language';
const GET_PARAMETER_PARAMETERCACHEID = 'parameterCacheId';
const GET_PARAMETER_TIMESTAMP = 'timestamp';
const GET_PARAMETER_ESTATEID = 'estateId';
const GET_PARAMETER_BUSINESSUNIT = 'businessUnit';
const GET_PARAMETER_USERID = 'userId';
const GET_PARAMETER_SIGNATURE = 'signature';

storeState.getParameters.apiClaim = getQueryParams()[GET_PARAMETER_APICLAIM];
storeState.getParameters.customerName = getQueryParams()[GET_PARAMETER_CUSTOMERNAME];
storeState.getParameters.customerWebId = getQueryParams()[GET_PARAMETER_CUSTOMERWEBID];
storeState.getParameters.groupId = getQueryParams()[GET_PARAMETER_GROUPID];
storeState.getParameters.language = getQueryParams()[GET_PARAMETER_LANGUAGE];
storeState.getParameters.parameterCacheId = getQueryParams()[GET_PARAMETER_PARAMETERCACHEID];
storeState.getParameters.timestamp = getQueryParams()[GET_PARAMETER_TIMESTAMP];
storeState.getParameters.estateId = getQueryParams()[GET_PARAMETER_ESTATEID];
storeState.getParameters.businessUnit = getQueryParams()[GET_PARAMETER_BUSINESSUNIT];
storeState.getParameters.userId = getQueryParams()[GET_PARAMETER_USERID];
storeState.getParameters.signature = getQueryParams()[GET_PARAMETER_SIGNATURE];


let vuestore: StoreOptions<typeof storeState> = {
    state: storeState,
}
let store = createStore(vuestore);

store.state.loginResponse = StorageServices.getLoginResponse();

export default store;

<template>
    <div class="googleLoginContainer">
        <div ref="signInButton" class="googleSignIn"></div>
        <div class="otherButtons">
            <button class="btn btn-primary ms-2" @click="revoke">
                Revoke
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import { getQueryParams } from '@/utils';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class googleAuthenticate extends Vue {

    password: string = "";
    token: string = "";

    mounted(){
        google.accounts.id.renderButton(this.$refs.signInButton, {})
    }


    revoke(){
        google.accounts.id.disableAutoSelect();
        google.accounts.id.revoke(store.state.googleAuthentication.userData.sub, (done) => {
            console.log(done);
        });
    }
    
}
</script>

<style scoped>

.googleLoginContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.googleSignIn, .otherButtons {
    display: inline-block;
}

</style>
<template>
    <div>
        <header class="d-flex justify-content-around align-items-center">
            <img src="@/img/logo.png" />
            <h1>Welcome to OnOffice Calendar Sync</h1>
        </header>
        <div class="container">
            <div class="content">
                <h3>About OnOffice Calendar Sync</h3>
                <p>OnOffice Calendar Sync is a powerful tool designed to help OnOffice users seamlessly synchronize their Google Calendar with their OnOffice calendar. This ensures that all your important events, meetings, and appointments are always up-to-date across both platforms, saving you time and preventing scheduling conflicts.</p>
                
                <h3 class="mt-3">Features</h3>
                <ul>
                    <li>Easy synchronization between Google Calendar and OnOffice calendar</li>
                    <li>Frequent updates to keep your calendars consistent</li>
                    <li>Simple and user-friendly interface</li>
                    <li>Secure data handling and privacy</li>
                </ul>
                
                <h3 class="mt-3">How does it work</h3>
                <p>
                    This tool is free and available exclusively for onOffice users. It can be activated through the internal marketplace. To use it, you need to log in with your Google account, allowing access to your calendar. This access will be used to read and write events and synchronize them with your organization’s onOffice calendar.
                    <br/>
                    The synchronization is bidirectional: onOffice appointments will be reflected in your Google Calendar and vice versa.
                    Shared resources in onOffice are linked to the corresponding calendars on Google.
                    To get started, find the service in the marketplace of your onOffice account and press activate!
                </p>
                
                <h3 class="mt-3">What if I had another syncing tool before?</h3>
                <p>
                    The first synchronization requires all users to have connected their Google account before starting. The tool will start from onOffice appointments and find all events that are duplicated across the users' calendars, and merge the duplicated events.
                    If multiple onOffice users are participating in the appointment, a single Google Calendar event will be created, using the appointment creator as the organizer of the event. All connected users and addresses will be saved as participants.
                </p>
                <p>
                    To find duplicates during the first synchronization, the rules used are:
                </p>
                <ul>
                    <li>same day</li>
                    <li>same hours</li>
                    <li>same title</li>
                </ul>
                <p>
                    For each appointment in the onOffice calendar, matching events will be removed from Google Calendar and recreated after, starting from the onOffice appointment.
                </p>
                
                <h3 class="mt-3">What is the timespan of synchronization?</h3>
                <p>
                    For the first synchronization, you can choose the starting date and the end date. Practically, we suggest not going wider than a one-year timespan, although it is possible.
                    For regular syncing operations, you can sync up to 1 year before and after the current date.
                </p>

                <hr/>

                <h3 class="mt-3">Do you have a privacy policy for Google Auth?</h3>
                <p>
                    You can find the complete information regarding how we use the Google Auth data at this link: <a href="https://onofficecalendarsync.com/privacy_policy.html" target="_blank" class="fs-5">Privacy Policy</a>
                </p>
            </div>
        </div>
        <footer>
            <div>
                "OnOffice Calendar Sync" is a tool developed for onOffice Italia Srl © 2024 <a href="https://it.onoffice.com/" target="_blank">https://it.onoffice.com/</a>
            </div>
            <div>
            </div>
        </footer>
    </div>
</template>
<template>
    <div class="text-center">
        <div>
            <localized-text localizedKey="Per iniziare, collega il tuo account google" text="Per iniziare, collega il tuo account google" v-if="!$store.state.isGoogleAuthorized"></localized-text>
            <localized-text localizedKey="Account google collegato" text="Account google collegato" v-else></localized-text>
        </div>
        <div class="mt-2">
            <button class="btn btn-primary" @click="requestAuthorize" v-if="!$store.state.isGoogleAuthorized || $store.state.user.hasRevokedAuth">
                <localized-text localizedKey="Collegati con google" text="Collegati con google"></localized-text>
            </button>
            <button class="btn btn-danger" @click="revoke" v-else>
                <localized-text localizedKey="Revoca autorizzazione" text="Revoca autorizzazione"></localized-text>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import { getQueryParams } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class googleAuthorize extends Vue {

    @Prop() responseCb;
    @Prop() revokeCb;

    codeClient: any = null;
    tokenClient: any = null;

    mounted(){
        this.codeClient = google.accounts.oauth2.initCodeClient({
            client_id: '988680744264-vu1tj2umi0ks3p7dtdu315cbe9m54mln.apps.googleusercontent.com',
            scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email',
            ux_mode: 'popup',
            callback: this.localResponseCb
        });
        this.tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: '988680744264-vu1tj2umi0ks3p7dtdu315cbe9m54mln.apps.googleusercontent.com',
            scope: 'https://www.googleapis.com/auth/calendar',
            callback: this.localTokenResponseCb
        });
    }

    requestAuthorize(){
        this.codeClient.requestCode();
    }

    localResponseCb(response) {
        store.state.googleAuthorization.userData = response;
        this.responseCb(response);
    }

    revoke(){
        this.tokenClient.requestAccessToken();
    }

    localTokenResponseCb(tokenResponse) {
        // console.log("tokenResponse", tokenResponse);
        google.accounts.oauth2.revoke(tokenResponse.access_token, () => {
            this.revokeCb();
        })
    }

}
</script>

<style scoped>

.googleLoginContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.googleSignIn, .otherButtons {
    display: inline-block;
}

</style>
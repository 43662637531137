import { IModalPlugin } from '@/plugins/modalPlugin/modalPlugin';
import AlertModal from '@/components/modals/alertModal.vue';
import ConfirmModal from '@/components/modals/confirmModal.vue';
import InputModal from '@/components/modals/inputModal.vue';

class _ModalServices {
    
    $opModal: any;
    setOpModal(input: IModalPlugin){
        this.$opModal = input;
    }

    alertModal(title: string, text: string, okText?: string, okCallback?: () => void ){
        okText = okText || "OK";
        this.$opModal.show(AlertModal, {
            title,
            text,
            okText,
            okCallback
        })
    }

    confirmModal(title: string, text: string, okText?: string, koText?: string, okCallback?: () => void, koCallback?: () => void ){
        okText = okText || "OK";
        this.$opModal.show(ConfirmModal, {
            title,
            text,
            okText,
            koText,
            okCallback,
            koCallback
        })
    }

    inputModal(title: string, text: string, okText: string, koText: string, value: string, 
            placeholder: string, okCallback: (input: string) => void, koCallback?: () => void){

        okText = okText || "OK";
        koText = koText || "Annulla";
        placeholder = placeholder || "Scrivi qui";
        this.$opModal.show(InputModal, {
            title,
            text,
            okText,
            koText,
            value,
            placeholder,
            okCallback,
            koCallback
        })
    }

}

export let ModalServices = new _ModalServices();
<template>
    <div class="switch" :class="{ checked: modelValue, monocolor: monocolor, readonly: readonly }" @click="toggle">
        <span class="slider round"></span>
    </div>
</template>

<script lang="ts">
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class Toggler extends mixins(WithModel) {

    @Prop() monocolor: boolean;

    @Prop() readonly: boolean;
    
    toggle(){
        if(this.readonly){
            return;
        }
        this.emitModel(!this.modelValue);
    }

}
</script>

<style scoped>

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        flex-shrink: 0;
    }

    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: .4s;
    }
    
    .switch.readonly .slider {
        cursor: not-allowed;
    }

    .switch.checked .slider, .switch.monocolor .slider {
        background-color: #80acd3;
    }
    .switch.checked .slider:before {
        transform: translateX(26px);
    }


    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

</style>

import * as OM from '@/model';

class _StorageServices  {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    setOpTableFilters(key: string, filters: any){
        window.localStorage.setItem('_op_' + key, JSON.stringify(filters));
    }
    getOpTableFilters(key: string){
        return this.readFromLocalStorage<any>('_op_' + key);
    }
    
    getLoginResponse() {
        return this.readFromLocalStorage<OM.LoginResponse>('LoginResponse');
    }
    setLoginResponse(response: OM.LoginResponse) {
        window.localStorage.setItem('LoginResponse', JSON.stringify(response));
    }
    
    getQueryParameters() {
        return this.readFromLocalStorage<string>('queryParameters');
    }
    setQueryParameters(parameters: { [key: string]: any }) {
        window.localStorage.setItem('queryParameters', JSON.stringify(parameters));
    }
    
    getSelectedLanguage() {
        return this.readFromLocalStorage<string>('SelectedLanguage');
    }
    setSelectedLanguage(lang: string) {
        window.localStorage.setItem('SelectedLanguage', JSON.stringify(lang));
    }
    
}

export let StorageServices = new _StorageServices();
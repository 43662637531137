<template>
    <div id="appComponent" v-if="showApp">
        <div class="testlabel" v-if="$store.state.isTestMode()">
            modalità test
        </div>
        <div class="globalSpinner" v-if="$store.state.globalSpinner > 0">
            <mini-spinner></mini-spinner>
        </div>
        <template v-if="isActivated">
            <router-view></router-view>
        </template>
        <template v-else-if="isActivation">
            <marketplace-activation></marketplace-activation>
        </template>
        <template v-else>
            <welcome-page></welcome-page>
        </template>
        <modals-container></modals-container>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CommonServices } from './services/CommonServices';
import { ModalServices } from './services/ModalServices';
import { LocalizedClient, MarketplaceClient } from './services/Services';
import { StorageServices } from './services/StorageServices';
import store from './store';
import {LoginServices} from './services/LoginServices';

@Options({})
export default class App extends Vue {

    showApp: boolean = false;
    isActivation: boolean = false;
    isActivated: boolean = false;

    created(){
        ModalServices.setOpModal(this.$opModal);

        CommonServices.unauthorizedCb = () => {
            store.state.isGoogleAuthorized = false;
        }

        let proms: any[] = [
            LocalizedClient.getAll(),
            LocalizedClient.getEnabledLanguages(),
        ];
        Promise.all(proms)
        .then(xs => {
            store.state.consts.localizedValues = xs[0];
            store.state.consts.enabledLanguages = xs[1];
            if(!store.state.selectedLanguage){
                store.state.selectedLanguage = xs[1][0];
            }
            if(store.state.loginResponse){
                LoginServices.loginCallback(store.state.loginResponse);
            }
        })
        
        window.onGoogleLibraryLoad = () => {
        };

        if(window.location.href.indexOf("activate") > -1){
            this.isActivation = true;
            this.showApp = true;
            return;
        }

        MarketplaceClient.checkActivation(store.state.getParameters.customerWebId)
        .then(x => {
            if(x || this.$store.state.isTestMode()){
                this.isActivated = true;
                this.showApp = true;
            } else {
                this.showApp = true;
            }
        })
    }

}
</script>
<template>
    <div>
        <div class="text-bold">
            <localized-text localizedKey="Error logs" text="Error logs"></localized-text>
        </div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <td>
                        <localized-text localizedKey="Date" text="Date"></localized-text>
                    </td>
                    <td>
                        <localized-text localizedKey="Dati utente" text="Dati utente"></localized-text>
                    </td>
                    <td>
                        <localized-text localizedKey="Corrispondenza" text="Corrispondenza"></localized-text>
                    </td>
                    <td>
                        <localized-text localizedKey="Messaggio" text="Messaggio"></localized-text>
                    </td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in errors" :key="i">
                    <td>
                        {{$filters.date(item.createdOn, "yyyy/MM/DD HH:mm:ss")}}
                    </td>
                    <td>
                        {{item.onOfficeUserIds.userId}} - 
                        {{item.onOfficeUserIds.webUserId}} - 
                        {{item.onOfficeUserIds.username}}
                    </td>
                    <td>
                        <div class="ellipsis">
                            <template v-if="item.eventIdPair">
                                {{item.eventIdPair.eventOrigin}}<br/>
                                {{item.eventIdPair.onOfficeId}}<br/>
                                {{item.eventIdPair.googleId}}<br/>
                            </template>
                        </div>
                    </td>
                    <td>
                        {{item.message}}
                    </td>
                    <td>
                        <button class="btn btn-danger" @click="dismiss(item)">
                            x
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { SyncErrorClient } from '@/services/Services'
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import { Prop, Watch } from 'vue-property-decorator';
import store from '@/store';

@Options({})
export default class errorsTable extends Vue {

    @Prop() reload: number;

    errors: OM.SyncError[] = [];

    @Watch('reload')
    onReload(next, prev){
        this.getErrors();
    }

    created(){
        this.getErrors();
    }

    getErrors(){
        this.errors = [];
        SyncErrorClient.getByWebUserId(this.$store.state.loginResponse.webUserId)
        .then(x => {
            this.errors = x;
        })
    }

    dismiss(item: OM.SyncError){
        SyncErrorClient.dismiss(item.identifier)
        .then(x => {
            this.getErrors();
        })
    }

}
</script>
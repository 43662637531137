<template>
    <div class="unlock-provider-form">
        <div class="unlock-api">
            <fieldset>
                <legend class="api-key-label">Incolla qui il codice per l'attivazione</legend>
                <div class="api-key-input-outer" id="secretDiv">
                    <input class="api-key-input" name="secret" type="text" placeholder="Incolla qui il codice per l'attivazione" id="secret" v-model="userSecretKey">
                </div>
                <label id="successMessage" class="active-label" v-if="done">Attivazione completata!</label>
                <input class="unlock-now-button" id="unlockButton" type="button" value="OK" title="Unlock service" alt="Unlock service" @click="activate" :disabled="!userSecretKey" v-else-if="!loading && !done">
            </fieldset>
        </div>
        <mini-spinner v-if="loading"></mini-spinner>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { getQueryParams } from '@/utils';
import { MarketplaceClient } from '@/services/Services'

@Options({})
export default class marketplaceActivation extends Vue {

    GET_PARAMETER_APICLAIM = 'apiClaim';
    GET_PARAMETER_APITOKEN = 'apiToken';
    GET_PARAMETER_CACHE_ID = 'parameterCacheId';
    GET_PARAMETER_SIGNATURE = 'signature';
    GET_PARAMETER_USERID = 'userId';
    GET_PARAMETER_CUSTOMERWEBID = 'customerWebId';
    

    userSecretKey: string = "";
    loading: boolean = false;
    done: boolean = false;

    $unlockProviderData: string = "";
    getParameterApiToken: string = "";
    getParameterCacheId: string = "";
    getParameterApiClaim: string = "";
    getParameterUserId: string = "";
    getParameterCustomerWebId: string = "";

    created(){
        this.getParameterApiToken = getQueryParams()[this.GET_PARAMETER_APITOKEN];
        this.getParameterCacheId = getQueryParams()[this.GET_PARAMETER_CACHE_ID];
        this.getParameterApiClaim = getQueryParams()[this.GET_PARAMETER_APICLAIM];
        this.getParameterUserId = getQueryParams()[this.GET_PARAMETER_USERID];
        this.getParameterCustomerWebId = getQueryParams()[this.GET_PARAMETER_CUSTOMERWEBID];

        let url = window.document.location.href;
        let questionPosition = url.indexOf("?");
        let urlWithoutParameters = url.substring(0, questionPosition);
        let parameters = url.substring(questionPosition);
        let signaturePosition = parameters.indexOf('&signature=');
        let signature = parameters.substring('&signature='.length + signaturePosition);
        let parametersWithoutSignature = parameters.substring(0, signaturePosition);
        let message = urlWithoutParameters + parametersWithoutSignature;
        MarketplaceClient.checkSignature(message, signature)
        .then(x => {
            console.log(x);
        })

    }
    
    activate(){
        this.loading = true;
        MarketplaceClient.activateMarketplace(this.userSecretKey, this.getParameterApiToken, this.getParameterApiClaim, this.getParameterCacheId, this.getParameterUserId, this.getParameterCustomerWebId, false)
        .then(x => {
            this.done = true;
            var target = window.parent;
            target.postMessage("active", '*');
        })
        .catch(err => {
            var target = window.parent;
            target.postMessage("Error during activation of the service", '*');
        })
        .finally(() => this.loading = false);
    }

}
</script>

<style scoped>

.unlock-provider-form .unlock-api {
    margin: 5px
}

.unlock-provider-form .unlock-api fieldset {
    border: 1px solid #d3d3d3
}

.unlock-provider-form .api-key-input {
    width: 75%;
    padding: 5px;
    float: left
}

.unlock-provider-form .unlock-now-button {
    float: right;
    width: 21%;
    padding: 5px;
    background-color: #ee7d01;
    color: #fff;
    border: 1px solid #ee7d01;
    cursor: pointer
}

.unlock-provider-form .active-label {
    font-weight: 700;
    color: #92c140;
    margin-left: 10px;
    display: inline-block;
    padding-top: 7px;
}


</style>

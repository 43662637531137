import { baseUrl } from '@/config';
import * as OM from '@/model'
import router from '@/router';
import store from "@/store";
import { CommonServices } from './CommonServices';
import { StorageServices } from "./StorageServices";
import { UserClient } from './Services';

class _LoginServices {

    loginCallback(loginResponse: OM.LoginResponse){
        store.state.loginResponse = loginResponse;
        StorageServices.setLoginResponse(loginResponse);
        CommonServices.setAuthToken(loginResponse.token);
        UserClient.getUser(store.state.loginResponse.userId, store.state.loginResponse.webUserId)
        .then(x => {
            if(x){
                store.state.isGoogleAuthorized = true;
                store.state.user = x;
                if(store.state.user.isFirstSyncDone){
                    router.push('/manageSync');
                } else {
                    router.push('/firstSync');
                }
            } else {
                store.state.isGoogleAuthorized = false;
            }
        })
        .catch(err => {
            console.log("catchato");
        })
    }

}

export let LoginServices = new _LoginServices();

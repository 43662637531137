import manageSync from '@/views/manageSync'
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/login.vue')
    },
    {
        path: '/',
        name: 'layout',
        component: () => import('@/views/layout.vue'),
        children: [
            ...manageSync.routes(),
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
